export class WorkspaceStatesConstant {
  static readonly ROOT = { STATE: "root" };
  static readonly LAUNCHPAD = { STATE: "launchpad" };
  static readonly DESKTOP = { STATE: "desktop" };
  static readonly DASHBOARD = { STATE: "dashboard" };
  static readonly DASHBOARD_OVERVIEW = { STATE: "dashboard_overview" };
  static readonly DASHBOARD_REPORT_SINGLE_VIEW = { STATE: "dashboard_report_single_view" };
  static readonly DASHBOARD_REPORT_MULTI_VIEW = { STATE: "dashboard_report_multi_view" };
  static readonly DASHBOARD_REPORT_EMAIL_SCHEDULES = { STATE: "dashboard_report_email_schedules" };
  static readonly WORKSPACE = { STATE: "workspace" };
  static readonly WORKSPACE_DETAIL = { STATE: "workspace_detail" };
  static readonly SEARCH_CUSTOMER = { STATE: "search" };
  static readonly WORKFLOW = { STATE: "workflow" };
  static readonly PERSONINFO = { STATE: "PERSON_INFO" };
  static readonly INITIAL_DISCLOSURE = { STATE: "INITIAL_DISCLOSURE" };
  static readonly PERSON_RELATIONSHIP = { STATE: "PERSON_RELATIONSHIP" };
  static readonly ADDRESS = { STATE: "ADDRESS" };
  static readonly ADDRESS_HISTORY = { STATE: "ADDRESS_HISTORY" };
  static readonly EMAIL = { STATE: "EMAIL" };
  static readonly PHONE = { STATE: "PHONE" };
  static readonly IDENTIFICATION = { STATE: "IDENTIFICATION" };
  static readonly INCOME = { STATE: "INCOME" };
  static readonly FUNDING = { STATE: "FUNDING" };
  static readonly ALLIED_FUNDING = { STATE: "ALLIED_FUNDING" };
  static readonly PHOBRANCH_SELECTIONNE = { STATE: "BRANCH_SELECTION" };
  static readonly PRODUCT_SELECTION = { STATE: "PRODUCT_SELECTION" };
  static readonly BRANCH_SELECTION = { STATE: "BRANCH_SELECTION" };
  static readonly BENEFICIARY_INFO = { STATE: "BENEFICIARY_INFO" };
  static readonly ROLES = { STATE: "ROLES" };
  static readonly MAILING = { STATE: "MAILING" };
  static readonly SERVICES = { STATE: "SERVICES" };
  static readonly CIP = { STATE: "CIP" };
  static readonly SUMMARY = { STATE: "SUMMARY" };
  static readonly FINAL_DISCLOSURE = { STATE: "FINAL_DISCLOSURE" };
  static readonly END = { STATE: "END" };
  static readonly BUSINESS_INFO = { STATE: "BUSINESS_INFO" };
  static readonly DOCUMENT = { STATE: "DOCUMENT" };
  static readonly BUSINESS_OWNERSHIP = { STATE: "BUSINESS_OWNERSHIP" };
  static readonly BUSINESS_RESOLUTION = { STATE: "BUSINESS_RESOLUTION" };
  static readonly DEMOGRAPHICS = { STATE: "DEMOGRAPHICS" };
  static readonly EMPLOYMENT = { STATE: "EMPLOYMENT" };
  static readonly EXPENSES = { STATE: "EXPENSES" };
  static readonly VERIFY_MEMBER = { STATE: "VERIFY_MEMBER" };
  static readonly WOLTERS_KLUWER = { STATE: "WOLTERS_KLUWER" };
  static readonly RIGHT_OF_SURVIVORSHIP = { STATE: "RIGHT_OF_SURVIVORSHIP" };
  static readonly LOGIN = { STATE: "login" };
  static readonly OPEN_NEW_ACCOUNT = { STATE: "opennewaccount" };
  static readonly ENROLLMENT_DEPOSIT_ACCOUNT = { STATE: "enrollmentdepositaccount" };
  static readonly ADD_PERSON_WORK_SPACE_SEARCH = { STATE: "addpersonworkspacesearch" };
  static readonly ADDITIONAL_INCOME = { STATE: "ADDITIONAL_INCOME" };
  static readonly REFERENCES = { STATE: "REFERENCES" };
  static readonly EMPLOYMENT_HISTORY = { STATE: "EMPLOYMENT_HISTORY" };
  static readonly ASSETS = { STATE: "ASSETS" };
  static readonly COLLATERAL = { STATE: "COLLATERAL" };
  static readonly LOAN_DECLARATION_QUESTIONS = { STATE: "LOAN_DECLARATION_QUESTIONS" }
  static readonly LOAN_DEMOGRAPHICS = { STATE: "LOAN_DEMOGRAPHICS" };
  static readonly LOAN_SIMPLE_UNSECURED = { STATE: "LOAN_SIMPLE_UNSECURED" };
}
