import EnrollmentDisclosureFactory from "@/common/services/Enrollment/enrollmentDisclosureFactory";
import ApplicantDisclosureFactory from "@/common/services/Applicant/ApplicantDisclosureFactory";
import { SendDisclosureModalConstant } from "@/common/constant/sendDisclosureModalConstant";
import DisclosureFactory from "@/common/services/Disclosure/DisclosureFactory";
import { injectable, inject } from "inversify";
import { isUndefined, cloneDeep, isObject, isNumber } from "lodash";
import type { IEnrollmentAccountPackage, Disclosure } from "@/common/models/IDisclosure";

@injectable()
class SendDisclosureModalFactory {
  constructor(
    @inject(EnrollmentDisclosureFactory) private enrollmentDisclosureFactory: EnrollmentDisclosureFactory,
    @inject(ApplicantDisclosureFactory) private applicantDisclosureFactory: ApplicantDisclosureFactory,
    @inject(DisclosureFactory) private disclosureFactory: DisclosureFactory
  ) {}

  getApplicantDisclosurePackageBySaved(
    applicantDisclosurePackage: IEnrollmentAccountPackage,
    isSaved: boolean
  ): IEnrollmentAccountPackage {
    const applicantDisclosurePackageCopy = cloneDeep(applicantDisclosurePackage);
    if (!isObject(applicantDisclosurePackageCopy)) {
      return {} as IEnrollmentAccountPackage;
    }
    applicantDisclosurePackageCopy.disclosures = applicantDisclosurePackageCopy.disclosures?.filter((disclosure) => {
      return isNumber(disclosure.disclosureStoreId) === isSaved;
    });
    return applicantDisclosurePackageCopy;
  }

  getEnrollmentDisclosurePackageBySaved(
    enrollmentDisclosurePackage: IEnrollmentAccountPackage[],
    isSaved: boolean
  ): IEnrollmentAccountPackage[] {
    let enrollmentDisclosurePackageCopy = cloneDeep(enrollmentDisclosurePackage);
    if (!Array.isArray(enrollmentDisclosurePackageCopy)) {
      return [];
    }
    enrollmentDisclosurePackageCopy = enrollmentDisclosurePackageCopy.filter((disclosurePackage) => {
      return disclosurePackage.disclosures?.some((disclosure) => {
        return !isUndefined(disclosure.disclosureStoreId) === isSaved;
      });
    });
    enrollmentDisclosurePackageCopy = enrollmentDisclosurePackageCopy.map((disclosurePackage) => {
      disclosurePackage.disclosures = disclosurePackage.disclosures?.filter((disclosure) => {
        return !isUndefined(disclosure.disclosureStoreId) === isSaved;
      });
      return disclosurePackage;
    });
    return enrollmentDisclosurePackageCopy;
  }

  flatMapDisclosurePackageListDisclosures(
    fn: any,
    disclosurePackageList: IEnrollmentAccountPackage[] | undefined
  ): IEnrollmentAccountPackage[] {
    return disclosurePackageList
      ? disclosurePackageList
          .reduce((collection: Disclosure[], disclosurePackage: IEnrollmentAccountPackage) => {
            disclosurePackage.disclosures = Array.isArray(disclosurePackage.disclosures)
              ? disclosurePackage.disclosures
              : ([] as Disclosure[]);
            collection = collection.concat(disclosurePackage.disclosures);
            return collection;
          }, [])
          .map((disclosure: Disclosure) => {
            return fn(disclosure);
          })
      : ([] as IEnrollmentAccountPackage[]);
  }

  mapDisclosurePackageListDisclosures(
    fn: any,
    disclosurePackageList: IEnrollmentAccountPackage[] | undefined
  ): IEnrollmentAccountPackage[] {
    return disclosurePackageList
      ? disclosurePackageList.map((disclosurePackage) => {
          disclosurePackage.disclosures = Array.isArray(disclosurePackage.disclosures)
            ? disclosurePackage.disclosures
            : [];
          disclosurePackage.disclosures = disclosurePackage.disclosures.map((disclosure) => {
            return fn(disclosure);
          });
          return disclosurePackage;
        })
      : ([] as IEnrollmentAccountPackage[]);
  }

  allDisclosuresEmailed(disclosurePackageList: IEnrollmentAccountPackage[]): boolean {
    const disclosures = this.flatMapDisclosurePackageListDisclosures(
      (disclosure: { emailActive: boolean; allowEmail: boolean }) => {
        return disclosure.emailActive || !disclosure.allowEmail;
      },
      disclosurePackageList
    );
    if (disclosures.length === 0) {
      return false;
    }
    return disclosures.every((isSelected) => {
      return isSelected;
    });
  }

  allDisclosuresPrinted(disclosurePackageList: IEnrollmentAccountPackage[] | undefined): boolean {
    const disclosures = this.flatMapDisclosurePackageListDisclosures(
      (disclosure: { printActive: boolean; allowPrint: boolean }) => {
        return disclosure.printActive || !disclosure.allowPrint;
      },
      disclosurePackageList
    );
    if (disclosures.length === 0) {
      return false;
    }
    return disclosures.every((isSelected) => {
      return isSelected;
    });
  }

  setAllPrintActive(disclosurePackageList: IEnrollmentAccountPackage[] | undefined): IEnrollmentAccountPackage[] {
    const toggle = !this.allDisclosuresPrinted(disclosurePackageList);
    return this.mapDisclosurePackageListDisclosures((disclosure: { printActive: boolean; allowPrint: boolean }) => {
      disclosure.printActive = disclosure.allowPrint ? toggle : false;
      return disclosure;
    }, disclosurePackageList);
  }

  setAllEmailActive(disclosurePackageList: IEnrollmentAccountPackage[]): IEnrollmentAccountPackage[] {
    const toggle = !this.allDisclosuresEmailed(disclosurePackageList);
    return this.mapDisclosurePackageListDisclosures((disclosure: { emailActive: boolean; allowEmail: boolean }) => {
      disclosure.emailActive = disclosure.allowEmail ? toggle : false;
      return disclosure;
    }, disclosurePackageList);
  }

  anySelected(disclosurePackageList: IEnrollmentAccountPackage[], sendType: string): boolean {
    return this.flatMapDisclosurePackageListDisclosures(
      (disclosure: { emailActive: boolean; printActive: boolean }) => {
        return sendType === SendDisclosureModalConstant.EMAIL ? disclosure.emailActive : disclosure.printActive;
      },
      disclosurePackageList
    ).some((isSelected) => {
      return isSelected;
    });
  }

  isEmpty(disclosurePackageList: IEnrollmentAccountPackage[]): boolean {
    return (
      this.flatMapDisclosurePackageListDisclosures((disclosure: IEnrollmentAccountPackage) => {
        return disclosure;
      }, disclosurePackageList).length === 0
    );
  }

  addApplicantIdsToEnrollmentAccountDisclosures(
    enrollmentAccountDisclosurePackageList: IEnrollmentAccountPackage[],
    uuid: string,
    enrollmentId: number
  ): Promise<IEnrollmentAccountPackage[]> {
    return Promise.all(
      enrollmentAccountDisclosurePackageList.map((enrollmentAccountDisclosurePackage) => {
        return this.enrollmentDisclosureFactory.addEnrollmentAccountDisclosureApplicantsToPackage(
          uuid,
          enrollmentId,
          enrollmentAccountDisclosurePackage
        );
      })
    );
  }

  printApplicantDisclosurePackage(uuid: string, applicantDisclosurePackage: IEnrollmentAccountPackage): Promise<void> {
    const disclosuresToPrint = applicantDisclosurePackage.disclosures.filter((disclosure) => {
      return disclosure.printActive;
    });
    return this.applicantDisclosureFactory.printApplicantDisclosure(
      uuid,
      applicantDisclosurePackage.id,
      disclosuresToPrint
    );
  }

  emailApplicantDisclosurePackage(
    uuid: string,
    emailAddress: string,
    applicantDisclosurePackage: IEnrollmentAccountPackage
  ): Promise<void> {
    const disclosuresToEmail = applicantDisclosurePackage.disclosures?.filter((disclosure) => {
      return disclosure.emailActive;
    });
    return this.applicantDisclosureFactory.emailApplicantDisclosure(
      uuid,
      applicantDisclosurePackage.id,
      emailAddress,
      disclosuresToEmail
    );
  }

  getDisclosuresFromPackages(disclosurePackages: IEnrollmentAccountPackage[]): IEnrollmentAccountPackage[] {
    return this.flatMapDisclosurePackageListDisclosures((disclosure: Disclosure) => {
      return disclosure;
    }, disclosurePackages);
  }

  emailEnrollmentDisclosurePackages(
    uuid: string,
    applicantId: number,
    enrollmentId: number,
    emailAddress: string,
    enrollmentPackages: IEnrollmentAccountPackage[]
  ): Promise<void> {
    const emailDisclosures = this.getDisclosuresFromPackages(enrollmentPackages)
      .filter(this.disclosureFactory.hasStoreId)
      .filter(this.disclosureFactory.hasEmailActive);
    return this.enrollmentDisclosureFactory.emailEnrollmentDisclosure(
      uuid,
      applicantId,
      emailAddress,
      emailDisclosures,
      enrollmentId
    );
  }

  printEnrollmentDisclosurePackages(
    uuid: string,
    applicantId: number,
    enrollmentId: number,
    enrollmentPackages: IEnrollmentAccountPackage[]
  ): Promise<void> {
    const printDisclosures = this.getDisclosuresFromPackages(enrollmentPackages)
      .filter(this.disclosureFactory.hasStoreId)
      .filter(this.disclosureFactory.hasPrintActive);
    return this.enrollmentDisclosureFactory.printEnrollmentDisclosure(
      uuid,
      applicantId,
      enrollmentId,
      printDisclosures
    );
  }

  filterEnrollmentApplicantDisclosurePackageByApplicant(
    enrollmentApplicantPackage: IEnrollmentAccountPackage[],
    applicant: { applicantId: number }
  ): IEnrollmentAccountPackage[] {
    const enrollmentApplicantPackageCopy = cloneDeep(enrollmentApplicantPackage);
    return enrollmentApplicantPackageCopy.filter((enrollmentApplicantPackage) => {
      return enrollmentApplicantPackage?.id === applicant.applicantId;
    });
  }

  filterEnrollmentAccountDisclosurePackageByApplicant(
    enrollmentAccountPackage: IEnrollmentAccountPackage[],
    applicant: { applicantId: number }
  ): IEnrollmentAccountPackage[] {
    const enrollmentAccountPackageCopy = cloneDeep(enrollmentAccountPackage);
    return enrollmentAccountPackageCopy
      .map((enrollmentAccountPackage) => {
        enrollmentAccountPackage.disclosures = enrollmentAccountPackage.disclosures?.filter((disclosure) => {
          return disclosure.applicants?.some((id: any) => {
            return applicant.applicantId === id;
          });
        });
        return enrollmentAccountPackage;
      })
      .filter((packageToFilter: { disclosures: Disclosure } | any) => {
        return packageToFilter.disclosures.length > 0;
      });
  }
}
export default SendDisclosureModalFactory;
