import {WorkspaceStatesConstant} from '@/branchmanager/app/constant/WorkspaceStatesConstant'
import {FeatureCodes} from '@/branchmanager/app/enums/featureCodesEnum'

export interface Site {
  route: string
  name: string
  icon: string
  featureCode: string
}

export const openAnywhereSites: Site[] = [
  {
    route: WorkspaceStatesConstant.DESKTOP.STATE,
    name: 'Workspace',
    icon: "<jha-icon-fi class='nav-icon'/>",
    featureCode: FeatureCodes.WORKSPACE
  },
  {
    route: WorkspaceStatesConstant.DASHBOARD.STATE,
    name: 'Dashboard',
    icon: "<jha-icon-dashboard class='nav-icon'/>",
    featureCode: FeatureCodes.DASHBOARD
  }
]
