import {defineStore} from 'pinia'

export const FdicStore = defineStore({
  id: 'FdicStore',
  state: () => ({
    showBanner: true,
    loadedTheFirstState: false,
    moveForward: () => {}
  }),
  actions: {
    reportMove() {
      if (this.loadedTheFirstState) {
        this.showBanner = false
      }
    }
  }
})
