<script setup lang="ts">

</script>

<template>
  <svg
      fill="none"
      viewBox="0 0 37.360001 15.739654"
      version="1.1"
      id="svg2"
      width="37.360001"
      height="15.739654"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
    <defs
        id="defs2" />
    <path
        clip-rule="evenodd"
        d="m 8.791,0.28965179 h 5.127 c 4.61,0 7.565,2.99300001 7.565,7.66700001 0,5.1720002 -3.267,7.6920002 -8.47,7.6920002 H 8.792 V 0.28865179 Z m 4.805,3.52000001 c -0.185,0 -0.42,0.031 -0.744,0.031 v 8.2800002 c 2.604,0 4.61,-1.092 4.61,-4.2490002 0,-2.714 -1.643,-4.055 -3.878,-4.055 l 0.012,-0.006 z m 23.764,0.948 V 0.70265179 a 7.597,7.597 0 0 0 -3.266,-0.699 c -2.03,-0.065 -3.998,0.74 -5.454,2.23300001 -1.455,1.492 -2.273,3.544 -2.266,5.684 0,4.5040002 3.393,7.8070002 7.64,7.8070002 a 5.036,5.036 0 0 0 3.346,-0.978 v -4.061 a 5.298,5.298 0 0 1 -3.134,1.123 3.625,3.625 0 0 1 -2.752,-1.168 4.04,4.04 0 0 1 -1.067,-2.9180002 4.121,4.121 0 0 1 1.072,-2.928 3.703,3.703 0 0 1 2.747,-1.212 5.442,5.442 0 0 1 3.134,1.148 z M 0,0.28965179 V 15.648652 H 4.01 L 4.038,9.7716518 h 3.9 v -3.551 H 4.062 l -0.023,-2.38 H 8.233 V 0.28965179 Z M 21.91,15.648652 h 4.009 V 0.28865179 h -4.01 V 15.648652 Z"
        fill="#003256"
        fill-rule="evenodd"
        id="path2" />
  </svg>
</template>