<script setup lang="ts">
import {useLink, useRoute, useRouter} from 'vue-router'
import {computed, getCurrentInstance, onMounted, ref, watch} from 'vue'
import FdicLogoDark from '@/open/components/FdicBanner/FdicLogoDark.vue'
import FdicLogoLight from '@/open/components/FdicBanner/FdicLogoLight.vue'
import {VsgFlexWrapper} from '@jack-henry/jha-vue-components'
import {WorkflowStatesConstant} from '@/common/constant/WorkflowStatesConstant'
import {FdicStore} from '@/open/components/FdicBanner/FdicStore'

const route = useRoute()
const store = FdicStore()

const logoColor = ref<string>()

onMounted(() => getTheme())
const getTheme = () => {
  logoColor.value = getComputedStyle(document.documentElement).getPropertyValue('--fdic-text-mode')
}

const STATES_TO_SHOW_FDIC_BANNER = [WorkflowStatesConstant.LOAN_FUNDING.STATE, WorkflowStatesConstant.FUNDING.STATE]

const logo = computed(() => (logoColor.value == 'Dark' ? FdicLogoDark : FdicLogoLight))
const isOnARequiredState = computed(() => {
  return route.name ? STATES_TO_SHOW_FDIC_BANNER.includes(route.name.toString()) : false
})
const fiHasFdicRequired = computed(() => {
  return window.institutionConfig['showFdicDisclaimer']
})
const isOnTheFirstState = computed(() => store.showBanner == true)

const showBanner = computed(() => {
  return fiHasFdicRequired.value && (isOnARequiredState.value || isOnTheFirstState.value)
})

const matchedRoute = computed(() => route.matched.map((route) => route.name))

window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
  getTheme()
})

const stateCountsAsFirst = (states: Array<String>) => {
  // The first state must be a workflow state
  return (
    states.includes(WorkflowStatesConstant.WORKFLOW.STATE) &&
    // The first state must NOT be a workflow action
    !states.includes(WorkflowStatesConstant.WORKFLOW_ACTION.STATE) &&
    // The first state must be an APPLICANT, ENROLLMENT, or DECISION state.
    (states.includes(WorkflowStatesConstant.APPLICANT.STATE) ||
      states.includes(WorkflowStatesConstant.ENROLLMENT.STATE) ||
      states.includes(WorkflowStatesConstant.DECISION.STATE))
  )
}

// If you land on a page, and it's parent is APPLICANT or ENROLLMENT, then you're on an info state
watch(matchedRoute, (newVal) => {
  if (stateCountsAsFirst(newVal as Array<string>)) {
    FdicStore().loadedTheFirstState = true
  }
})
</script>
<template>
  <vsg-flex-wrapper
    class="fdic-container"
    v-if="showBanner">
    <component
      class="logo"
      :is="logo" />
    <div class="fdic-text">FDIC-Insured - Backed by the full faith and credit of the U.S. Government</div>
  </vsg-flex-wrapper>
</template>

<style scoped>
.fdic-container {
  justify-content: center;
  padding: var(--jh-space-200) var(--jh-space-400);
  background: var(--footer-background-color);
}

.logo {
  flex-shrink: 0;
}

.fdic-text {
  color: var(--footer-text-color);
  flex-shrink: 1;
  white-space: normal;
  font-size: 13px;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell,
    Ubuntu, roboto, noto, arial, sans-serif;
  margin-left: var(--jh-space-200);
  font-style: italic;
}
</style>
