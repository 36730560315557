<template>
  <div data-cy="addressDisplayHeader" class="primary-text">{{ bltHeader }}</div>
  <div data-cy="addressDisplayLineOne" class="secondary-text" v-if="bltAddress">{{ bltAddress.address1}}</div>
  <div data-cy="addressDisplayLineTwo" class="secondary-text" v-if="bltAddress">{{ bltAddress.address2 }}</div>
  <div data-cy="addressDisplayLineOne" class="secondary-text" v-if="addressConfirmation">{{ addressConfirmation.street1 }}</div>
  <div data-cy="addressDisplayLineTwo" class="secondary-text" v-if="addressConfirmation">{{ addressConfirmation.street2 }}</div>
  <div data-cy="addressDisplayCityStateZip" class="secondary-text" v-if="bltAddress || addressConfirmation">
    {{ displayCityStateZipValue }}
  </div>
</template>

<script lang="ts">
import {defineComponent, type PropType} from "vue";
import type AddressConfirmation from "@/common/data/AddressConfirmation";

export default defineComponent({
  name: "bltAddress",
  props: {
    bltHeader: String,
    bltAddress: {
      type: Object as PropType<{
        address1: string
        address2?: string
        city?: string
        state?: string
        zipCode?: string
      }>
    },
    addressConfirmation: {
      type: Object as PropType<AddressConfirmation>
    }
  },
  computed: {
    displayCityStateZipValue() {
      return this.getFormattedAddress(
          this.bltAddress?.city ?? this.addressConfirmation?.city,
          this.bltAddress?.state ?? this.addressConfirmation?.stateProvince,
          this.bltAddress?.zipCode ?? this.addressConfirmation?.zipCode
      );
    }
  },
  methods:{
    getFormattedAddress(city: string|undefined, state: string|undefined, zipCode: string|undefined): string{
      const value = `${city ? city + "," : ""} ${state || ""} ${zipCode || ""}`.trim();
      if(value.endsWith(','))
      {
        return value.substring(0, value.length-1); //remove comma
      }
      return value;
    }
  }
});
</script>
