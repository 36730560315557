<script setup lang="ts">

</script>

<template>
  <svg
      fill="none"
      viewBox="0 0 37.360001 15.739654"
      version="1.1"
      id="svg2"
      width="37.360001"
      height="15.739654"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
    <defs
        id="defs2" />
    <g
        fill="#ffffff"
        id="g2"
        transform="translate(0,-0.38434821)">
      <path
          clip-rule="evenodd"
          d="m 8.791,0.674 h 5.127 c 4.61,0 7.565,2.993 7.565,7.667 0,5.172 -3.267,7.692 -8.47,7.692 H 8.792 V 0.673 Z m 4.805,3.52 c -0.185,0 -0.42,0.031 -0.744,0.031 v 8.28 c 2.604,0 4.61,-1.092 4.61,-4.249 0,-2.714 -1.643,-4.055 -3.878,-4.055 L 13.596,4.195 Z M 37.36,5.142 V 1.087 A 7.597,7.597 0 0 0 34.094,0.388 c -2.03,-0.065 -3.998,0.74 -5.454,2.233 -1.455,1.492 -2.273,3.544 -2.266,5.684 0,4.504 3.393,7.807 7.64,7.807 A 5.036,5.036 0 0 0 37.36,15.134 V 11.073 A 5.298,5.298 0 0 1 34.226,12.196 3.625,3.625 0 0 1 31.474,11.028 4.04,4.04 0 0 1 30.407,8.11 4.121,4.121 0 0 1 31.479,5.182 3.703,3.703 0 0 1 34.226,3.97 5.442,5.442 0 0 1 37.36,5.118 Z M 0,0.674 v 15.359 h 4.01 l 0.028,-5.877 h 3.9 V 6.605 H 4.062 L 4.039,4.225 H 8.233 V 0.674 Z m 21.91,15.359 h 4.009 V 0.673 h -4.01 v 15.36 z"
          fill-rule="evenodd"
          id="path2" />
    </g>
  </svg>
</template>