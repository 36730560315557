import { injectable, inject } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import type IFeature from "@/common/models/IFeature";

@injectable()
class FeatureFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  /**
   * Returns a list of all features for the active user.
   *
   * @remarks
   * The underlying Launchpad API currently returns all features assigned to the user, even those where the feature
   * itself has been disabled in the 'launchpad_feature' table.  Furthermore, the API does not populate the 'enabled'
   * flag on the returned DTO, so there is no way to know which features are enabled.  This will need to be fixed on
   * the API side if we want to have the ability to globally disable certain features for all users and have that be
   * reflected in the UI.
   */
  queryCached(params: any): Promise<Array<IFeature>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.LAUNCHPAD}/active/feature`,
      { params },
      ResponseTypes.Payload,
      true
    );
  }
}
export default FeatureFactory;
