<template>
  <blt-modal-dialog
    :disclosureHeightHotfix="true"
    @confirm="acceptDisclosure"
    @cancel="closeDisclosure"
    showConfirm
    :show-footer="showFooter"
    :confirmLabel="acceptText"
    :showDialog="showDisclosureDialog"
    :aria-label="disclosure.disclosureTitle"
    :confirmDisabled="disclosureStatus"
    preventAutoClose
    type="large-dialog">
    <template v-slot:dialog-header>
      <div class="w-100">
        <h1>{{ disclosure.disclosureTitle }}</h1>
        <h2>{{ secondaryTitle }}</h2>
      </div>
      <jha-button
        icon
        type="button"
        @click="closeDisclosure"
        class="reset">
        <jha-icon-close />
      </jha-button>
    </template>
    <template v-slot:dialog-content>
      <div
        style="overflow: hidden"
        class="w-100 container">
        <div
          v-if="errorMessages.length !== 0"
          class="blt-notification-wrapper pb-12"
          id="workflowNotificationScroll">
          <blt-notification :messages="errorMessages" />
        </div>
        <iframe
          v-if="!loading"
          id="disclosureId"
          title="disclosure"
          class="disclosure-content-iframe"
          ref="disclosureContent"
          v-blt-iframe="{
            html: disclosure.disclosure,
            dictionary: disclosure.data
          }">
        </iframe>
        <div v-if="loading"><blt-contentloader :contentLoader="loading"></blt-contentloader></div>
      </div>
    </template>
  </blt-modal-dialog>
</template>

<script lang="ts">
import {defineComponent, type PropType} from 'vue'
import {DisclosureConstant} from '@/common/constant/DisclosureConstant'
import {BroadcastService, LanguageFactory} from '@/common/services/services.module'
import {$inj} from '@/common/decorators/depinject'
import {OpenLanguageKeysConstant} from '@/common/constant/OpenLanguageKeysConstant'
import type {IErrorMessage} from '@/common/models/IErrorMessage'
import bltNotification from '@/common/components/bltNotification/bltNotification.vue'
import type {BltIframeData} from '@/common/directives/bltIframe/BltIframeData'
import type {Disclosure} from '@/common/models/IDisclosure'
import BltModalDialog from '@/common/components/bltDialog/bltModalDialog.vue'
import BltContentloader from '@/common/components/bltContentloader/bltContentloader.vue'

export default defineComponent({
  name: 'disclosureDialog',
  emits: ['disclosureClosed', 'acceptDisclosure'],
  components: {
    BltContentloader,
    BltModalDialog,
    bltNotification
  },
  props: {
    disclosure: {
      required: true,
      type: Object as PropType<Disclosure>
    },
    secondaryTitle: {
      required: false,
      type: String
    },
    showFooter: {
      required: false,
      type: Boolean,
      default: true
    },
    showDisclosureDialog: {
      required: true,
      type: Boolean
    },
    isFinalDisclosure: {
      type: Boolean,
      default: false
    },
    isAccountApplicantDisclosure: {
      type: Boolean,
      default: false
    },
    isLastDisclosureToAccept: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array<IErrorMessage>,
      required: false,
      default: []
    }
  },
  data() {
    return {
      DisclosureConstant,
    }
  },
  setup() {
    const languageFactory = $inj(LanguageFactory)
    const broadcastService = $inj(BroadcastService)
    return {
      languageFactory,
      broadcastService
    }
  },
  computed: {
    acceptText() {
      if (this.isFinalDisclosure) {
        return this.isLastDisclosureToAccept
          ? this.languageFactory.getSynchronous(OpenLanguageKeysConstant.OPEN_FINAL_DISCLOSURE_BUTTON_ACCEPT)
          : // To Do -> Use the text from the server when API is ready
            'Accept and Next'
      }

      if (this.isAccountApplicantDisclosure) {
        return this.languageFactory.getSynchronous(
          OpenLanguageKeysConstant.OPEN_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_ACCEPT
        )
      }

      return this.isLastDisclosureToAccept
        ? this.languageFactory.getSynchronous(OpenLanguageKeysConstant.OPEN_INITIAL_DISCLOSURE_BUTTON_SUBMIT)
        : // To Do -> Use the text from the server when API is ready
          'Accept and Next'
    },
    disclosureStatus() {
      return this.disclosure.status === DisclosureConstant.COMPLETE
    }
  },
  methods: {
    getBltIframeData(): BltIframeData {
      return (this.$refs.disclosureContent as any).getDictionary()
    },
    acceptDisclosure() {
      this.$emit('acceptDisclosure', this.getBltIframeData())
    },
    closeDisclosure() {
      this.$emit('disclosureClosed')
    }
  }
})
</script>
<style scoped>
.container {
  min-height: calc(86vh - 150px);
  display: flex;
  flex-direction: column;
}

.disclosure-content-iframe {
  width: 100%;
  border: 0;
  overflow: hidden;
  flex-grow: 1;
}

.disclosure-content-iframe input {
  width: 100%;
}

h2 {
  color: var(--jha-text-base);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 0;
}

@media (max-width: 599px) {
  .disclosure-content-iframe {
    min-height: calc(82vh - 90px);
  }
}
@media (max-width: 375px) {
  .disclosure-content-iframe {
    min-height: calc(77vh - 90px);
  }
}
@media (max-width: 360px) {
  .disclosure-content-iframe {
    min-height: calc(76vh - 90px);
  }
}
</style>
