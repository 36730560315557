import { isUndefined } from 'lodash';
import LookupFactory from '@/common/services/Lookup/LookupFactory';
import HttpHandler from "@/common/services/connect/HttpHandler";
import { inject, injectable } from 'inversify';
import ResponseTypes from "@/common/enums/responseTypesEnum";
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants';
import type IPhone from '@/common/data/IPhone'


@injectable()
class ApplicantPhoneFactory {
    constructor(
        @inject(HttpHandler) private httpHandler: HttpHandler,
        @inject(LookupFactory) private lookupFactory: LookupFactory
    ) { }

    getApplicantPhoneList(workspaceUUID: string, applicantId: number): Promise<IPhone[]> {
        return this.httpHandler.get(
            `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/applicant/${applicantId}/phone`,
            {}, ResponseTypes.Payload)
            .then((response): IPhone[] => {
                return (Array.isArray(response)) ? response : [];
            });
    }

    deleteApplicantPhone(workspaceUUID: string, applicantId: number, phone: any) {
        return this.httpHandler.delete(
            `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/applicant/${applicantId}/phone/${phone.phoneId}`,
            {}, ResponseTypes.Payload);
    }

    async saveApplicantPhone(workspaceUUID: string, applicantId: number, phone: any) {
        return (phone.phoneId)
            ? this.putApplicantPhone(workspaceUUID, applicantId, phone)
            : this.postApplicantPhone(workspaceUUID, applicantId, phone);
    }

    async saveApplicantPhoneList(workspaceUUID: string, applicantId: number, phoneList: any) {
        return Promise.all(phoneList.map((phone: any) => {
            return this.saveApplicantPhone(workspaceUUID, applicantId, phone);
        }));
    }

    async putApplicantPhone(workspaceUUID: string, applicantId: number, phone: any) {
        return await this.httpHandler.put(
            `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/applicant/${applicantId}/phone/${phone.phoneId}`, phone,
            {}, ResponseTypes.Payload)
            .then((response: any) => {
                return response[0];
            });
    }

    async postApplicantPhone(workspaceUUID: string, applicantId: number, phone: any) {
        return await this.httpHandler.post(
            `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/workspace/${workspaceUUID}/applicant/${applicantId}/phone`, phone,
            {}, ResponseTypes.Payload)
            .then((response: any) => {
                phone.phoneId = response[0].phoneId;
                return response[0];
            });
    }

    async setPhoneTypeDescription(phone: any) {

        if (isUndefined(phone.phoneType)) {
            return;
        }

        return await this.lookupFactory.Phone?.getAll({ enabled: true })
            .then((phoneTypes: any[]) => {
                phone.phoneTypeDescription = phoneTypes.filter((phoneType) => {
                    return phoneType.typeCode === phone.phoneType;
                })[0].typeDescription;
            });
    }

    async setPhoneListTypeDescription(phoneList: any) {
        return Promise.all(phoneList.map((phone: any) => {
            return this.setPhoneTypeDescription(phone);
        }));
    }

}
export default ApplicantPhoneFactory;
