<script lang="ts">
import { $inj } from "@/common/decorators/depinject";
import { LanguageFactory } from "@/common/services/services.module";
import { defineComponent } from "vue";

export default defineComponent({
  name: "bltEmptyData",
  props: {
    bltText: {
      type: String,
      default: "No data"
    },
    bltUiText: {
      type: String
    }
  },
  setup() {
    const languageFactory = $inj(LanguageFactory);
    return {
      languageFactory
    };
  },
  data() {
    return {
      labelText: "" as string
    };
  },
  computed: {
    labelComputed: function() {
      return this.bltText ?? this.labelText
    }
  },
  created() {
    this.setLabel();
  },
  methods: {
    setLabel() {
      if (this.bltUiText) {
        this.languageFactory.get(this.bltUiText).then((response) => {
          this.labelText = response;
        });
      } else {
        this.labelText = this.bltText;
      }
    }
  }
});
</script>

<template>
  <div class="no-location show-hide">
    <vsg-flex-wrapper class="empty-data-wrapper">
      <slot name="jhaIcon"></slot>
      <div class="pt-8">{{ labelComputed }}</div>
    </vsg-flex-wrapper>
  </div>
</template>

<style scoped>
.empty-data-wrapper {
  text-align: center;
  color: var(--body-text-secondary-color);
  min-height: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
