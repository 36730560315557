import type {ObjectDirective} from '@vue/runtime-core'
import ShadowDomService from '@/common/components/bltForm/shadowDomService'
import {$inj} from '@/common/decorators/depinject'
import type JhaFormTextAreaInput from '@banno/jha-wc/src/forms/jha-form-textarea-input/JhaFormTextAreaInput'
import {computed, nextTick, watch} from 'vue'

const textareaResizeOnMount = {
  mounted(element: JhaFormTextAreaInput) {
    nextTick().then(() => {
      const shadowDomService = $inj(ShadowDomService)
      const textareaElem = shadowDomService.querySelectorAll('textarea', element)[0]

      const event = new Event('input', {
        bubbles: true
      })

      const callInput = () => {
        if (textareaElem.scrollHeight) {
          textareaElem.dispatchEvent(event)
        } else {
          requestAnimationFrame(callInput)
        }
      }

      requestAnimationFrame(callInput)
    })
  }
} as ObjectDirective

export default textareaResizeOnMount
