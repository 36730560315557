import {AdminStatesConstant} from '@/branchmanager/app/constant/AdminStatesConstant'
import {FeatureCodes} from '@/branchmanager/app/enums/featureCodesEnum'

export interface Module {
  name: string
  route: string
  featureCode: string
}
export const managementModules: Module[] = [
  {
    name: 'Disclosures',
    route: AdminStatesConstant.DISCLOSURES.STATE,
    featureCode: FeatureCodes.DISCLOSURES
  },
  {
    name: 'Relationships',
    route: AdminStatesConstant.RELATIONSHIPS.STATE,
    featureCode: FeatureCodes.RELATIONSHIPS
  },
  {
    name: 'OAuth providers',
    route: AdminStatesConstant.OAUTH.STATE,
    featureCode: FeatureCodes.OAUTH_PROVIDERS
  },
  {
    name: 'OCR',
    route: AdminStatesConstant.OCR.STATE,
    featureCode: FeatureCodes.OCR
  },
  {
    name: 'Business',
    route: AdminStatesConstant.BUSINESS.STATE,
    featureCode: FeatureCodes.BUSINESS
  },
  {
    name: 'CIP',
    route: AdminStatesConstant.CIP_ADMIN.STATE,
    featureCode: FeatureCodes.CIP
  },
  {
    name: 'Synergy',
    route: AdminStatesConstant.SYNERGY.STATE,
    featureCode: FeatureCodes.SYNERGY
  },
  {
    name: 'Wolters Kluwer',
    route: AdminStatesConstant.WOLTERS_KLUWER.STATE,
    featureCode: FeatureCodes.WOLTERS_KLUWER
  },
  {
    name: 'Pigeon',
    route: AdminStatesConstant.PIGEON_ADMIN.STATE,
    featureCode: FeatureCodes.PIGEON
  },
  {
    name: 'Fields',
    route: AdminStatesConstant.FIELDS_ADMIN.STATE,
    featureCode: FeatureCodes.FIELDS
  },
  {
    name: 'ZIP Codes',
    route: AdminStatesConstant.ZIP_CODE.STATE,
    featureCode: FeatureCodes.ZIP_CODES
  },
  {
    name: 'Verbiage',
    route: AdminStatesConstant.VERBIAGE.STATE,
    featureCode: FeatureCodes.VERBIAGE
  },
  {
    name: 'Promo codes',
    route: AdminStatesConstant.PROMO_CODES.STATE,
    featureCode: FeatureCodes.PROMO_CODES
  },
  {
    name: 'Scheduled tasks',
    route: AdminStatesConstant.SCHEDULED_TASKS.STATE,
    featureCode: FeatureCodes.SCHEDULED_TASKS
  },
  {
    name: 'Products',
    route: AdminStatesConstant.ADMIN_PRODUCTS.STATE,
    featureCode: FeatureCodes.PRODUCTS
  },
  {
    name: 'Properties',
    route: AdminStatesConstant.PROPERTIES_ADMIN.STATE,
    featureCode: FeatureCodes.PROPERTIES
  },
  {
    name: 'Questionnaire',
    route: AdminStatesConstant.QUESTIONNAIRE_ADMIN.STATE,
    featureCode: FeatureCodes.QUESTIONNAIRE
  },
  {
    name: 'Export files',
    route: AdminStatesConstant.DOCUMENT_EXPORTS_ADMIN.STATE,
    featureCode: FeatureCodes.EXPORT_FILES
  },
  {
    name: 'Funding',
    route: AdminStatesConstant.FUNDING.STATE,
    featureCode: FeatureCodes.FUNDING
  },
  {
    name: 'Services',
    route: AdminStatesConstant.ADMIN_SERVICE_LIST.STATE,
    featureCode: FeatureCodes.SERVICES
  },
  {
    name: 'Users & groups',
    route: AdminStatesConstant.USERS.STATE,
    featureCode: FeatureCodes.USERS_GROUPS
  },
  {
    name: 'Mapping',
    route: AdminStatesConstant.ADMIN_MAPPING.STATE,
    featureCode: FeatureCodes.MAPPING
  },
  {
    name: 'Workflows',
    route: AdminStatesConstant.WORKFLOW_ADMIN.STATE,
    featureCode: FeatureCodes.WORKFLOWS
  },
  {
    name: 'Enrollment Intentions',
    route: AdminStatesConstant.ADMIN_INTENTIONS.STATE,
    featureCode: FeatureCodes.ENROLLMENT_INTENTIONS
  },
  {
    name: 'Rules',
    route: AdminStatesConstant.RULES_ADMIN.STATE,
    featureCode: FeatureCodes.RULES
  }
]
