export enum FeatureCodes {
  WORKSPACE = 'new_ui_workspace',
  NEW_PERSON_WORKSPACE = 'new_ui_new_person_workspace',
  NEW_BUSINESS_WORKSPACE = 'new_ui_new_business_workspace',
  EXISTING_WORKSPACES = 'new_ui_existing_workspaces',
  SHOW_JOINT_AND_CHECKBOX = 'new_ui_show_joint_and_checkbox',
  DASHBOARD = 'new_ui_dashboard',
  DASHBOARD_SETTINGS = 'new_ui_dashboard_settings',
  MANAGEMENT_CONSOLE = 'new_ui_management_console',
  BUSINESS = 'new_ui_business',
  CIP = 'new_ui_cip',
  DISCLOSURES = 'new_ui_disclosures',
  ENROLLMENT_INTENTIONS = 'new_ui_enrollment_intentions',
  EXPORT_FILES = 'new_ui_export_files',
  FIELDS = 'new_ui_fields',
  FUNDING = 'new_ui_funding',
  MAPPING = 'new_ui_mapping',
  OAUTH_PROVIDERS = 'new_ui_oauth_providers',
  OCR = 'new_ui_ocr',
  PIGEON = 'new_ui_pigeon',
  PRODUCTS = 'new_ui_products',
  PROMO_CODES = 'new_ui_promo_codes',
  PROPERTIES = 'new_ui_properties',
  QUESTIONNAIRE = 'new_ui_questionnaire',
  RELATIONSHIPS = 'new_ui_relationships',
  RULES = 'new_ui_rules',
  SCHEDULED_TASKS = 'new_ui_scheduled_tasks',
  SERVICES = 'new_ui_services',
  SYNERGY = 'new_ui_synergy',
  USERS_GROUPS = 'new_ui_users_groups',
  VERBIAGE = 'new_ui_verbiage',
  WOLTERS_KLUWER = 'new_ui_wolters_kluwer',
  WORKFLOWS = 'new_ui_workflows',
  ZIP_CODES = 'new_ui_zip_codes'
}
