import { isObject } from "lodash";
import NotificationFactory from "@/common/services/notificationFactory";
import { inject, injectable } from "inversify";
import HttpHandler from "@/common/services/connect/HttpHandler";
import SERVICE_PATH_CONSTANTS from "../../constant/servicePathConstants";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import LookupFactory from "../Lookup/LookupFactory";
import { EnrollmentConstant } from "@/common/constant/EnrollmentConstant";
import ENROLLMENT_TYPES from "@/common/enums/enrollmentTypesEnum";
import BroadcastService from "@/common/services/Broadcast/BroadcastService";
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import type IEnrollment from "@/common/models/IEnrollment";

@injectable()
class EnrollmentFactory {
  constructor(
    @inject(NotificationFactory) private notificationFactory: NotificationFactory,
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @inject(LookupFactory) private lookupFactory: LookupFactory,
    @inject(BroadcastService) private broadcastService: BroadcastService
  ) {}

  // you can implement query method
  from(workspaceUUID: string, enrollmentId?: number): Promise<Array<IEnrollment>> {
    if (enrollmentId) {
      return this.httpHandler.get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}`,
        {},
        ResponseTypes.Payload
      );
    } else {
      return this.httpHandler.get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment`,
        {},
        ResponseTypes.Payload
      );
    }
  }

  async configureShare(workspaceUUID: string, enrollment: { enrollmentId: number }) {
    return await this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollment.enrollmentId}`,
      enrollment,
      { params: { verifyShare: true } }
    );
  }
  /*end alternative implementation to $resource */

  async getOrPost(workspaceUUID: string) {
    return this.getAll(workspaceUUID).then((response) => {
      /* When there are no results, API returns null :) */
      return response !== null && response.length ? Promise.resolve(response) : this.postAndNotify(workspaceUUID);
    });
  }

  async postAndNotify(workspaceUUID: string) {
    return this.post(workspaceUUID, { enrollmentType: "DEPOSIT" }).then((response) => {
      this.notificationFactory.info(EnrollmentConstant.ENROLLMENT_TOAST_SERVICE_INFO);
      return response;
    });
  }

  async getAll(workspaceUUID: string) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment`,
      {},
      ResponseTypes.Payload
    );
  }

  async delete(workspaceUUID: string, enrollmentId: number) {
    return await this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}`,
      {},
      ResponseTypes.Data
    );
  }

  async post(workspaceUUID: string, message: any, branchLocationId?: number, intention?: string) : Promise<IEnrollment[]> {
    const getMessage = message || {
      enrollmentType: ENROLLMENT_TYPES.DEPOSIT,
      branchLocationId: branchLocationId,
      intention,
    };
    return await this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment`,
      getMessage,
      {},
      ResponseTypes.Payload
    );
  }

  async updateEnrollmentState(uuid: string, enrollmentId: number, state: any) {
    this.broadcastService.broadcast(BroadcastConstant.UPDATE_ENROLLMENT_STATE, {
      enrollmentId: enrollmentId,
      enrollmentState: state
    });
    return await this.lookupFactory.EnrollmentState?.getAll().then((response: any) => {
      return response.some((enrollmentState: any) => {
        return enrollmentState.typeCode === state;
      })
        ? this.httpHandler.put(
            `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}/state`,
            {
              enrollmentId: enrollmentId,
              enrollmentState: state
            },
            {},
            ResponseTypes.Data
          )
        : Promise.resolve();
    });
  }

  getEnrollment(uuid: string, enrollmentId: number) {
    return this.httpHandler
      .get(
        `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollmentId}`,
        {},
        ResponseTypes.Payload
      )
      .then((response) => {
        return Array.isArray(response) ? response[0] : [];
      });
  }

   putEnrollment(uuid: string, enrollment: any) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${uuid}/enrollment/${enrollment.enrollmentId}`,
      enrollment,
      {},
      ResponseTypes.Payload
    );
  }

  async getCoreHeader() {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.BOLTSBRANCH}/core/header`,
      {},
      ResponseTypes.Payload,
      true
    );
  }

  getEnrollmentAccounts(workspaceUUID: string, enrollmentId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account`,
      {},
      ResponseTypes.Payload
    );
  }
  getEnrollmentApplicants(workspaceUUID: string, enrollmentId: number) {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/applicant`,
      {},
      ResponseTypes.Payload
    );
  }

  async getEnrollmentValidation(workspaceUUID: string, enrollmentId: number) {
    return await this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/validate?type=ALL`,
      {},
      ResponseTypes.Payload
    );
  }
  async hasAccounts(enrollment: { accounts: any[] }) {
    if (!isObject(enrollment) || !Array.isArray(enrollment.accounts)) {
      return false;
    }
    return enrollment.accounts.length > 0;
  }
}
export default EnrollmentFactory;
