import { inject, injectable } from "inversify";
import { WorkflowStatesConstant } from "@/common/constant/WorkflowStatesConstant";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type IRouterService from "@/common/services/utils/IRouterService";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";

@injectable()
class ErrorFactory {
  constructor(
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private workspaceMetadataStore: IWorkspaceMetadataStore,
    @inject(DEPENDENCYTYPES.IRouterService) private router: IRouterService
  ) { }

  stopApplicationWithInternalError() {
    this.displayErrorString("Sorry, we are unable to open your account at this time.")
  }

  setErrorMessage(error: { data?: { message: string } }) {
    this.workspaceMetadataStore.error.errorMessage = error?.data?.message;
  }

  getErrorMessage() {
    return this.workspaceMetadataStore.error.errorMessage;
  }

  displayError(error: { data?: { message: string } }) {
    this.setErrorMessage(error);
    this.router.push({ name: WorkflowStatesConstant.ERROR.STATE });
  }

  displayErrorString(message: string) {
    this.workspaceMetadataStore.error.errorMessage = message;
    this.router.push({ name: WorkflowStatesConstant.ERROR.STATE });
  }

  setEodError(eodErrorstring: string) {
    this.workspaceMetadataStore.error.eodError = eodErrorstring;
  }

  displayEodError() {
    this.displayErrorString(this.workspaceMetadataStore.error.eodError);
  }
}
export default ErrorFactory;
