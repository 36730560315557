<template>
  <div ref="container" style="width: 300px; min-width: 300px; height: 40px; min-height: 40px">
    <button
      ref="signBtn"
      v-if="!value"
      :disabled="!editable"
      style="height: 100%; width: 100%; cursor: pointer; font-size: 1rem; border: 0"
      :style="[
        editable
          ? { background: signBtnBgColor, color: signBtnTxtColor }
          : { cursor: 'not-allowed', opacity: 0.7, background: signBtnBgColor, color: signBtnTxtColor }
      ]"
    >
      {{ sign }}
    </button>
    <bltSignatureDisplay :data="data" v-if="value"></bltSignatureDisplay>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import bltSignatureDisplay from "@/common/components/bltSignatureDisplay/bltSignatureDisplay.vue";
export default defineComponent({
  name: "bltSignButton",
  components: {
    bltSignatureDisplay
  },
  props: {
    value: {
      type: Boolean,
    },
    editable: {
      type: Boolean,
    },
    data: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      sign: "SIGN" as string,
      signBtnBgColor: "" as string,
      signBtnTxtColor: "" as string
    };
  },
  emits: ["input"],
  mounted() {

    const button = this.$refs.signBtn as HTMLElement;

    if(!button) throw new Error("An error occurred while initializing the bltSignButton component, button ref is undefined.");

    button.addEventListener("click", () => { this.$emit("input", true) })

    const styles = getComputedStyle(document.documentElement);
    this.signBtnBgColor = styles.getPropertyValue("--jha-button-background");
    this.signBtnTxtColor = styles.getPropertyValue("--jha-button-text");

  }
});
</script>
