import { inject, injectable } from "inversify";
import SERVICE_PATH_CONSTANTS from "@/common/constant/servicePathConstants";
import HttpHandler from "@/common/services/connect/HttpHandler";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import {isUndefined} from "lodash";
import type IPhone from "@/common/data/IPhone";
import type LeafAddress from "@/common/data/LeafAddress";


@injectable()
class ApplicantEmploymentFactory {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}
  get(workspaceUUID: string, applicantId: number): Promise<ApplicantEmployment | undefined> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/employment`,
      undefined,
      ResponseTypes.Payload      
    );
  }
  private post(workspaceUUID: string, applicantId: number, employment: ApplicantEmployment) {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/employment`,
      employment,
      undefined,
      ResponseTypes.Payload      
    );
  }
  private put(workspaceUUID: string, applicantId: number, employment: ApplicantEmployment) {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/applicant/${applicantId}/employment/${employment.employmentId}`,
      employment,
      undefined,
      ResponseTypes.Payload      
    );
  }
  save(workspaceUUID: string, applicantId: number, employment: ApplicantEmployment): Promise<ApplicantEmployment> {
    if (isUndefined(employment.employmentId)) {
      return this.post(workspaceUUID, applicantId, employment);
    } else {
      return this.put(workspaceUUID, applicantId, employment);
    }
  }
}

export interface ApplicantEmployment {
  employmentId?: string
  employerName?: string
  title?: string
  address: LeafAddress
  occupation?: string
  employmentStartDate?: string
  yearsOfEmployment?: ApplicantEmploymentYearsOfEmployment
  phone?: IPhone
  grossAnnualIncome?: string | number,
}

export interface ApplicantEmploymentYearsOfEmployment {
  employmentYears?: number;
  employmentMonths?: number
}

export default ApplicantEmploymentFactory;
