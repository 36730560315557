import axios, {
  AxiosError,
  type AxiosInstance,
  type AxiosResponse
} from "axios";

import { inject, injectable } from "inversify";
import type CacheableAxiosRequestConfig from "@/common/services/connect/CacheableAxiosRequestConfig";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import DEPENDENCYTYPES from "@/common/dependency.types";
import ResponseTypes from "@/common/enums/responseTypesEnum";
import { HttpCacheAdapter } from "./HttpCacheAdapter";
import { isEmpty } from "lodash";
import { $inj } from "@/common/decorators/depinject";
import BroadcastService from "@/common/services/Broadcast/BroadcastService";
import { BroadcastConstant } from "@/common/constant/BroadcastConstant";
import { useAxiosRequestInterceptor, useAxiosResponseInterceptor } from "@/common/utils/axios/axiosUtils";
import type HttpHandlerConfig from "@/common/interfaces/httpHandler/HttpHandlerConfig";

@injectable()
class HttpHandler {

  private axiosInstance: AxiosInstance;
  private httpCacheAdapter: HttpCacheAdapter;

  constructor(
    @inject(DEPENDENCYTYPES.IWorkspaceMetadataStore) private store: IWorkspaceMetadataStore,
    @inject(DEPENDENCYTYPES.HttpHandlerConfig) private httpHandlerConfig: HttpHandlerConfig
  ) {

    this.axiosInstance = axios.create({
      baseURL: "",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache"
      },
    });

    this.httpCacheAdapter = new HttpCacheAdapter(this.axiosInstance, this.store, 2);

    this
      .httpHandlerConfig
      .requestInterceptors
      .forEach(it => useAxiosRequestInterceptor(this.axiosInstance, it))

    this.httpHandlerConfig
      .responseInterceptors
      .forEach(it => useAxiosResponseInterceptor(this.axiosInstance, it))

    //todo(mikol): This should be happening in the constructor of this class... VVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV
    const baseUrl = window.location.origin;

    const proxyTarget = import.meta.env.VITE_APP_PROXY_TARGET;

    // Vite will host localhost at a random port that we don't have context over, so when we see a user try to use
    // localhost, we will just set our baseUrl to the origin of the window to capture that port.
    if(proxyTarget) {
      store.baseUrl = window.location.origin;
    } else {
      store.baseUrl = baseUrl;
      if (isEmpty(store.baseUrl)) {
        store.baseUrl = import.meta.env.BASE_URL;
      }
    }
    // todo(mikol): ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  }

  //todo(mikol): this should be deprecated, everything should just return the response.
  private parseResponse(response: AxiosResponse, respondOption?: ResponseTypes) {
    const broadcastService = $inj(BroadcastService);

    broadcastService.broadcast(BroadcastConstant.DISPLAY_TOAST, response);
    if (!respondOption || respondOption === ResponseTypes.Resource) {
      return response;
    }
    if (respondOption === ResponseTypes.None) {
      return response;
    }
    if (respondOption === ResponseTypes.Data) {
      return response && response.data ? response.data : null;
    }
    if (respondOption === ResponseTypes.Payload) {
      return response && response.data && response.data.payload ? response.data.payload : null;
    }
    return response;
  }

  private rejectWithAxiosResponse(error: AxiosError): Promise<AxiosResponse> {
    return Promise.reject(error.response)
  }

  public async get(
    url: string,
    config?: CacheableAxiosRequestConfig | undefined,
    respondOption?: ResponseTypes,
    cache?: boolean
  ): Promise<any> {
    const enableCaching = cache || (config ? config?.cache : false);
    return this.httpCacheAdapter
      .execute_get(url, config, enableCaching)
      .then(res => this.parseResponse(res, respondOption))
      .catch(this.rejectWithAxiosResponse)
  }

  async post(url: string, data?: unknown, config?: CacheableAxiosRequestConfig | undefined, respondOption?: ResponseTypes): Promise<any> {
    return this.axiosInstance.post(url, data, config)
      .then(res => this.parseResponse(res, respondOption))
      .catch(this.rejectWithAxiosResponse)
  }

  async put(url: string, data?: unknown, config?: CacheableAxiosRequestConfig | undefined, respondOption?: ResponseTypes): Promise<any> {
    return this.axiosInstance.put(url, data, config)
      .then(res => this.parseResponse(res, respondOption))
      .catch(this.rejectWithAxiosResponse)
  }

  async delete(url: string, config?: CacheableAxiosRequestConfig | undefined, respondOption?: ResponseTypes): Promise<any> {
    return this
      .axiosInstance
      .delete(url, config)
      .then(res => this.parseResponse(res, respondOption))
      .catch(this.rejectWithAxiosResponse)
  }
}

export default HttpHandler;
