import {cloneDeep, isEmpty, isString, isUndefined} from 'lodash'
import {BltIframeConstants} from '@/common/constant/BltIframeConstant'
import LogService from '@/common/services/Log/LogService'
import {$inj, $injByInterface} from '@/common/decorators/depinject'
import {Base64Factory, BltIframeFactory} from '@/common/services/services.module'
import bltSignButton from '@/common/components/bltSignButton/bltSignButton.vue'
import type {DirectiveBinding, VNode} from 'vue'
import {createApp, type Directive, h} from 'vue'
import type IWorkspaceStore from '@/common/services/Workspace/IWorkspaceStore'
import type {DisclosureTag} from '@/common/directives/bltIframe/DisclosureTag'
import type {DisclosureDictionary} from '@/common/directives/bltIframe/DisclosureDictionary'
import type {BltIframeData} from '@/common/directives/bltIframe/BltIframeData'

const defaultHtml =
  "" + "<html lang=\"en\">" + "   <body>" + '       <div style="min-height:300px; width:100%;"></div>' + "   </body>" + "</html>";

const getIframeDoc = (el: { contentDocument: any; contentWindow: any; }): Document | null => {
  if (el.contentWindow?.document?.getElementById) {
    return el.contentWindow?.document
  } else if (el.contentDocument?.getElementById) {
    return el.contentDocument
  } else {
    return null
  }
};

const getIframeBody = (el: HTMLIFrameElement) => {
  const doc = getIframeDoc(el);
  return !isUndefined(doc) ? doc?.body : undefined;
};

const getDictionary = (el: HTMLIFrameElement, dictionary: DisclosureDictionary): BltIframeData => {

  const dictionaryCopy: DisclosureDictionary = cloneDeep(dictionary);

  const tags: DisclosureTag[] = Object.keys(dictionaryCopy).map((tagKey) => getValueFromHtml(el, dictionaryCopy[tagKey]))

  const populatedDictionary: DisclosureDictionary = tags.reduce((col: DisclosureDictionary, tag: DisclosureTag) => {
    col[tag.tagName] = tag;
    return col;
  }, {});
  return { dictionary: populatedDictionary };
};

const getValueFromHtml = (el: HTMLIFrameElement, tag: DisclosureTag) => {
  // const logService = $inj(LogService);
  const iframeDoc = getIframeDoc(el);
  const tagElem = iframeDoc?.getElementById(tag.tagName) as HTMLElement

  if (tagElem === null) {
    return tag;
  }

  switch (tag.tagType) {
    case BltIframeConstants.TEXTBOX:
      tag.value = (<HTMLTextAreaElement> tagElem)?.value;
      break;
    case BltIframeConstants.CHECKBOX:
      tag.value = (<HTMLInputElement> tagElem)?.checked;
      break;
    case BltIframeConstants.SELECT:
      tag.value = -1 !== (<HTMLSelectElement> tagElem).selectedIndex
          ? ((<HTMLOptionElement> (<HTMLSelectElement> tagElem)[(<HTMLSelectElement> tagElem).selectedIndex])).value
          : "";
      break;
    case BltIframeConstants.SIGNATURE:
      const signed = tagElem.getAttribute("signed") === "true";
      tag.value = JSON.parse(tag.value);
      tag.signed = signed.toString();
      tag.value.signed = signed;
      tag.value = JSON.stringify(tag.value);
      break;
  }
  return tag;
};

const writeHtml = (iframe: HTMLIFrameElement, html: string, vnode: VNode<HTMLIFrameElement>, dictionary?: any, maxCardContentHeight?: number) => {
  const bltIframeFactory = $inj(BltIframeFactory);
  html = isString(html) ? html : defaultHtml;
  const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
  if(iframeDoc == null) { return }
  iframeDoc.open();
  iframeDoc.write(html);
  iframeDoc.close();

  waitForIframeLoad(iframe).then(() => {
    const iframeDoc = getIframeDoc(iframe);
    if (!iframeDoc) { return; }

    if (!isEmpty(dictionary)) {
      const tagMap = bltIframeFactory.getElementTagMap(iframe);
      injectSignatures(dictionary as any, iframeDoc);
      injectSelect(iframeDoc, dictionary as any);
      bltIframeFactory.setTextboxValues(tagMap, dictionary as any);
    }

    if (maxCardContentHeight) {
      /**BLOCK OF CODE TO CALCULATE IFRAME CONTENT BODY HEIGHT and RESIZE IFRAME HEIGHT */
      const marginTop = parseInt(window.getComputedStyle(iframeDoc.body).getPropertyValue('margin-top'));
      const marginBottom = parseInt(window.getComputedStyle(iframeDoc.body).getPropertyValue('margin-bottom'));
      const paddingTop = parseInt(window.getComputedStyle(iframeDoc.body).getPropertyValue('padding-top'));
      const paddingBottom = parseInt(window.getComputedStyle(iframeDoc.body).getPropertyValue('padding-bottom'));
      const iframeBodyHeight = iframeDoc.body.scrollHeight + marginTop + marginBottom + paddingTop + paddingBottom;

      //this block of code is being used only from ShowDisclosre type of screen
      if (iframeBodyHeight > maxCardContentHeight) {
        iframe.height = `${maxCardContentHeight}`;
      }
      else {
        iframe.height = `${iframeBodyHeight + 35}`;
      }
      const parentWrapperDiv = (iframe as HTMLIFrameElement).parentElement;
      if (parentWrapperDiv)
        parentWrapperDiv.style.height = `${iframe.height}px`;
    }
  });
};

const updateIframe = (dictionary: any, html: string, el: HTMLIFrameElement, uuid: any, enrollmentId: any, vnode: VNode<HTMLIFrameElement>, maxCardContentHeight?: number) => {
  if (uuid && !isUndefined(uuid) && !isEmpty(dictionary) && isString(html)) {

    processHtml(dictionary, html, el, uuid, enrollmentId).then((data: { html: string; dictionary: any }) => {
      writeHtml(el, data.html, vnode, data.dictionary, maxCardContentHeight);
    });
  } else {
    writeHtml(el, html, vnode, null, maxCardContentHeight);
  }
};

const waitForIframeLoad = (ele: HTMLIFrameElement) => {
  return new Promise<HTMLElement>((resolve) => {
    const timeInterval = setInterval(() => {
      const iframeBody = getIframeBody(ele);
      const iframeDoc = getIframeDoc(ele);

      if (iframeBody && iframeDoc) {
        clearInterval(timeInterval);
        resolve(iframeBody);
      }
    }, 20);
  });
};

const processHtml = (dictionary: any, html: string, content: HTMLIFrameElement, uuid: string, enrollmentId: number) => {
  const bltIframeFactory = $inj(BltIframeFactory);

  const dictionaryCloned = cloneDeep(dictionary),
    sortedDictionary = bltIframeFactory.sortDictionaryTags(dictionaryCloned);

  bltIframeFactory.getCheckboxReplacements(sortedDictionary);

  bltIframeFactory.getTextboxReplacements(sortedDictionary);

  bltIframeFactory.getSelectReplacements(sortedDictionary);

  bltIframeFactory.getSignatureReplacements(sortedDictionary);

  return bltIframeFactory.injectApplicantImages(sortedDictionary, uuid, enrollmentId).then(() => {
    const finalHTML = bltIframeFactory.replaceHtmlTags(html, sortedDictionary);
    return { html: finalHTML, dictionary: sortedDictionary };
  });
};

const waitForSignature = (signature: { originalTagName: string }, content: any): any => {

  // todo(mikol): We should just listen to the mounted signature component's lifecycle events, this is not necessary.

  // todo(mikol): Is it really necessary to query the document 150000 times?
  let timeInterval = 0;
  return new Promise<any>((resolve, reject) => {
    const elemInterval = setInterval(() => {
      const signatureDiv = content.querySelectorAll('[' + CSS.escape(signature.originalTagName) + ']')[0]

      if (!isUndefined(signatureDiv)) {
        clearInterval(elemInterval)
        resolve(signatureDiv)
      }
      if (timeInterval / 5 === 30000) {
        clearInterval(elemInterval)
        reject()
      }
      timeInterval++
    }, 5)

  })
};

const injectSignatures = (dictionary: { signature: any[] }, content: Document) => {
  const base64Factory = $inj(Base64Factory);
  dictionary.signature.forEach((signature) => {

    if(!signature.base64?.imageDataBase64) {
      console.error(`Error injecting signature into tag: ${signature.tagName}, no base64.imageDataBase64 was found for this image, it is possible that a signature has yet to be collected in this case..., data: `, signature);
    }

    if (!isUndefined(signature.base64) && isString(signature.base64.imageDataBase64)) {
      const signatureData = base64Factory.prefixBase64(signature.base64.imageDataBase64, signature.base64.imageFormat);

      waitForSignature(signature, content).then((signatureDiv: { setAttribute: (arg0: string, arg1: any) => void; appendChild: (arg0: any) => void }) => {

        signatureDiv.setAttribute("id", signature.tagName);

          const EmbeddedSignatureApp = createApp({
            mounted() {
              this.setSignedAttribute(signature.value === "true");
              this.signed = signature.value.signed;
            },
            data() {
              return {
                signed: false,
              }
            },
            methods: {
              setSignedAttribute (value: boolean) {
                signatureDiv.setAttribute("signed", value.toString());
              },
            },
            setup() {
              return function () {
                return h(bltSignButton, {
                  editable: signature.editable,
                  value: this.signed,
                  data: signatureData,
                  onInput: (value: boolean) => {
                    this.setSignedAttribute(value)
                    this.signed = value
                  }
                })
              }
            }
          });

          const wrapper = document.createElement("div");
          EmbeddedSignatureApp.mount(wrapper);

          const getSignatureId = content.getElementById(signature.tagName);
          if (getSignatureId) {
            getSignatureId.appendChild(wrapper);
          }
        });
    }
  });
};

const injectSelect = (iframeDoc: Document, dictionary: { select: any[] }) => {
  dictionary.select.forEach((tag) => {
    if (isString(tag.tagName) && isString(tag.value)) {
      const tagElem = iframeDoc.getElementById(tag.tagName) as HTMLSelectElement

      if (tagElem) {
        tagElem.value = tag.value;
      } else {
        console.warn(`Tag ${tag.tagName} not found in iFrame`)
      }
    }
    if (isString(tag.tagName)) {
      const tagElem = iframeDoc.getElementById(tag.tagName) as HTMLSelectElement
      if (tagElem) {
        tagElem.disabled = !tag.editable
      } else {
        console.warn(`Tag ${tag.tagName} not found in iFrame`)
      }
    }
  });
};

interface bltIframeValues {
  html: string,
  dictionary: any,
  disclosureStatus: string,
  maxCardContentHeight?: number
}

interface HTMLIframeTagElement extends HTMLIFrameElement {
  getDictionary(): any;
}

const bltIframe: Directive<HTMLIFrameElement, bltIframeValues> = {

  mounted: (
      el: HTMLIframeTagElement,
      binding: DirectiveBinding<bltIframeValues>,
      vnode: VNode<HTMLIFrameElement>
  ) => {

    el.getDictionary = () => getDictionary(el, binding.value.dictionary);

    const workspaceStore = $injByInterface<IWorkspaceStore>("IWorkspaceStore");
    const uuid = workspaceStore.workspaceUUID;
    const enrollmentId = workspaceStore.enrollment.enrollmentId;
    const { html, dictionary, maxCardContentHeight } = binding.value;
    updateIframe(dictionary, html, el, uuid, enrollmentId, vnode, maxCardContentHeight);

    },

  updated(
      el: HTMLIFrameElement,
      binding: DirectiveBinding<bltIframeValues>,
      vnode: VNode<HTMLIFrameElement>
  ) {

    const { html, dictionary, maxCardContentHeight } = binding.value;

    updateIframe(dictionary, html, el, null, null, vnode, maxCardContentHeight);

  },


};

export default bltIframe;
