const SERVICE_PATH_CONSTANTS = {
  BOLTSBRANCH: "{{boltsbranch}}",
  BOLTSBRANCH_CORE_URL: "{{boltsbranch}}/core",
  BOLTSUI: "{{boltsui}}",
  BOLTSUI_WORKFLOW: "{{boltsui}}/workflow",
  WORKSPACE_URL_TEMPLATE: "{{boltsbranch}}/workspace",
  LAUNCHPAD: "{{launchpad}}",
  PALETTE: "palette",
  PROPERTY: "property",
  DOCUMENT: "document",
  BANNO_ENROLLMENT_POST_URL: "BANNO_ENROLLMENT_POST_URL",
  SERVICE_CODE_BOLTSOPEN: "boltsopen",
  SERVICE_CODE_BOLTSBRANCH: "boltsbranch",
  APP_CODE_NEW_UI: "new_ui"
};

export default SERVICE_PATH_CONSTANTS;
